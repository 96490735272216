//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*师傅接口---------------------------*/
//获取师傅列表接口
export function getmasterData(obj) {
    return request({
        url: '/api/admin/worker?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//师傅详情接口
export function postediview(obj,url) {
    return request({
        url: '/api/admin/worker/view',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//提现列表接口
export function getCashList(obj) {
    return request({
        url: '/api/admin/worker/getCashList',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//提现日志接口
export function getCashLog(obj,url) {
    return request({
        url: '/api/admin/worker/cashLog',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}//师傅广告接口
export function posteditStatus(obj,url) {
    return request({
        url: '/api/admin/worker/editStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postAuditStatus(obj,url) {
    return request({
        url: '/api/admin/worker/AuditStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}



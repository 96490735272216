<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 310px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                        width="55" label="#" >
      </el-table-column>
      <el-table-column prop="real_name" label="真实姓名">
      </el-table-column>
      <el-table-column width="110" prop="amount" label="提现金额" >
        <template #default="scope">
          ￥{{scope.row.amount}}
        </template>
      </el-table-column>
      <el-table-column width="110" prop="balance" label="账户余额" >
        <template #default="scope">
          ￥{{scope.row.balance}}
        </template>
      </el-table-column>
      <el-table-column width="160" prop="check_time" label="审核时间" >
      </el-table-column>
      <el-table-column width="176" prop="bank_card" label="银行卡号" >
<!--        <template #default="scope">-->
<!--          ￥{{scope.row.balance}}-->
<!--        </template>-->
      </el-table-column>
      <el-table-column width="172" prop="bank_name" label="银行" >
      </el-table-column>
      <el-table-column width="138" prop="status" label="状态" >
        <template #default="scope">
          <div v-if="scope.row.status==0">
            发起提现
          </div>
          <div v-else-if="scope.row.status==1">
            已受理(财务审核)
          </div>
          <div v-else-if="scope.row.status==2">
            银行处理中
          </div>
          <div v-else-if="scope.row.status==3">
            到账完成
          </div>
          <div v-else-if="scope.row.status==4">
            失败
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" >
        <template #default="scope">
          <span v-if="scope.row.check_status==0">
            无
          </span>
          <span v-else-if="scope.row.check_status==1">
            审核中
          </span>
          <span v-else-if="scope.row.check_status==2">
            审核通过
          </span>
          <span v-else-if="scope.row.check_status==3">
            审核失败
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="340">
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" v-if="scope.row.status==0" type="primary"  @click="editStatus(scope.row,1)">已受理</el-button>
              <el-button size="mini" v-if="scope.row.status==1" type="primary"  @click="editStatus(scope.row,2)">银行处理中</el-button>
              <el-button size="mini" v-if="scope.row.status==2" type="primary"  @click="editStatus(scope.row,3)">到账完成</el-button>
              <el-button size="mini" v-if="scope.row.status==2" type="primary"  @click="editStatus(scope.row,4)">失败</el-button>
            </el-button-group>

            <el-button-group style="margin-left: 5px">
            <el-button size="mini"  :disabled="scope.row.check_status!=0&&scope.row.check_status!=1" type="primary"  @click="AuditStatus(scope.row,2)">审核通过</el-button>
              <el-button size="mini"  :disabled="scope.row.check_status!=0&&scope.row.check_status!=1" type="primary"  @click="AuditStatus(scope.row,3)">审核驳回</el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/masterApi"//本页面接口
export default {
  name: 'brand',
  // props:['nowData'],
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
      },
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        // worker_id:this.nowData.worker_id
      }
      axios.getCashList(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    upData(row){
      axios.postediview({id:row.id}).then((res)=>{
        if(res.code==200){
          this.ruleForm=res.data
          // this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },
    editStatus(row,val){
      axios.posteditStatus({id:row.id,type:val}).then((res)=>{
        if(res.code==200){
          this.$message.success(res.msg)
          this.ruleForm=res.data
          // this.drawer=true
          this.onTable()
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },
    AuditStatus(row,val){
      axios.postAuditStatus({id:row.id,type:val}).then((res)=>{
        if(res.code==200){
          this.$message.success(res.msg)
          this.ruleForm=res.data
          // this.drawer=true
          this.onTable()
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>